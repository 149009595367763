/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Capes Editor.
 *
 * Capes Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Capes Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Capes Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import WickModal from 'Editor/Modals/WickModal/WickModal';
import ActionButton from 'Editor/Util/ActionButton/ActionButton';

import './_editorinfo.scss';
import ToolIcon from '../../Util/ToolIcon/ToolIcon';

class WelcomeModal extends Component {
    render () {
        return (
            <WickModal
            open={this.props.open} 
            toggle={this.props.toggle}
            className="editor-info-modal-container"
            overlayClassName="editor-info-modal-overlay">
                <div className="editor-info-modal-body">
                    <div className="editor-info-icon">
                        <ToolIcon name="mascot"/>
                    </div>
                    <div className="editor-info-name">Capes Editor</div>
                    <div className="editor-info-version">Version 0.0.1</div>
                </div> 
            </WickModal>
        );
    }
}
export default WelcomeModal